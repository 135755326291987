import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { get, post } from 'utils/api'
import { LandingSettings } from 'types'
import config from 'config'
import { LandingState, SendContactEmailPayload } from './types'
import { REDUCER_NAME } from './config'

const initialState: LandingState = {
  isLoading: false,
  areProductsFetching: false,
  errors: [],
  baner: '',
  description: '',
  featuredProducts: [],
  subtitle: '',
  title: '',
}

export const fetchLandings = createAsyncThunk<AxiosResponse>(
  'fetchLandings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await get('landings', {
        headers: {
          Authorization: `Basic ${config.API_KEY}`,
        },
      })
      return response
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const fetchFeaturedProducts = createAsyncThunk<AxiosResponse>(
  'fetchFeaturedProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await get('products/featured', {
        headers: {
          Authorization: `Basic ${config.API_KEY}`,
        },
      })
      return response
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const sendContactEmail = createAsyncThunk<
  AxiosResponse,
  SendContactEmailPayload
>('sendContactEmail', async (data, { rejectWithValue }) => {
  try {
    const response = await post('contacts/email', {
      headers: {
        Authorization: `Basic ${config.API_KEY}`,
      },
      data,
    })
    return response
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response)
  }
})

export const updateLandings = createAsyncThunk<
  LandingSettings,
  LandingSettings
>('updateLandings', async (data, { rejectWithValue }) => {
  try {
    await post('landings', {
      data,
      headers: {
        Authorization: `Basic ${config.API_KEY}`,
      },
    })
    return data
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response)
  }
})

export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLandings.pending.type]: state => {
      state.isLoading = true
    },
    [fetchLandings.rejected.type]: (state, action) => {
      state.isLoading = false
    },
    [fetchLandings.fulfilled.type]: (state, { payload: { data } }) => {
      state.isLoading = false
      state.baner = data.baner
      state.description = data.description
      state.subtitle = data.subtitle
      state.title = data.title
    },
    [updateLandings.pending.type]: state => {
      state.isLoading = true
    },
    [updateLandings.rejected.type]: (state, action) => {
      state.isLoading = false
    },
    [updateLandings.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.description = payload.description
      state.subtitle = payload.subtitle
      state.title = payload.title
    },
    [fetchFeaturedProducts.pending.type]: state => {
      state.areProductsFetching = true
    },
    [fetchFeaturedProducts.rejected.type]: (state, action) => {
      state.areProductsFetching = false
    },
    [fetchFeaturedProducts.fulfilled.type]: (state, { payload }) => {
      state.areProductsFetching = false
      state.featuredProducts = payload.data || []
    },
  },
})

export default slice.reducer
