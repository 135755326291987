import { Module } from 'types'
export * from './api'
export * from './dates'
export * from './hooks'
export * from './user'
export const isArray = Array.isArray
const isObjectOrFunction = (obj: any) =>
  typeof obj === 'object' || typeof obj === 'function'

export const mergeArrays = <T>(arr1: T[], arr2: T[]) => [...arr1, ...arr2]

export const numericBoolean = (arg: any) => (arg ? 1 : 0)

export const mergeObjects = <T>(
  obj1: { [key: string]: T },
  key: string,
  obj2: T
) => ({
  ...obj1,
  [key]: obj2,
})

export const reduceConfig = <T>(
  key: keyof Module,
  modules: Module[],
  initialValue: any = []
): T[] =>
  modules.reduce((configs, module) => {
    const config = module[key]
    if (isArray(config)) return mergeArrays(configs, config as T[])
    else if (isObjectOrFunction(config))
      return mergeObjects(configs, module.name, config)
    return [...configs, config]
  }, initialValue)

export const getTranslationKeys = <T>(moduleName: string, strings: T) =>
  Object.entries(strings).reduce<T>(
    (obj, [key, value]) => ({ ...obj, [key]: `${moduleName}_${value}` }),
    {} as T
  )

export const addBasePath = <T>(basePath: string, paths: T): T =>
  Object.entries(paths).reduce(
    (pathsChanged, [key, path]) => ({
      ...pathsChanged,
      [key]: `${basePath}${path}`,
    }),
    {} as T
  )

export const chainPromise = ([initialPromise, ...promises]: ((
  ...args: any
) => Promise<any>)[]) => (...args: any) =>
  promises.reduce(
    (prevPromise, currentPromise) => prevPromise.then(currentPromise),
    initialPromise(args)
  )
