import { createSlice } from '@reduxjs/toolkit'
import { LandingState } from './types'
import { REDUCER_NAME } from './config'

const initialState: LandingState = {
  isLoading: false,
  baner: '',
  description: '',
  subtitle: '',
  errors: [],
  title: '',
}

export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
})

export default slice.reducer
