import { styled, Button, ButtonProps, WithTheme } from '@material-ui/core'

const MENU_ITEM_HEIGHT = 72
const ICON_SIZE = 24

interface WrapperProps extends ButtonProps, WithTheme {
  styled: {
    active: boolean
  }
}

export const getIconStyle = (active?: boolean) => ({
  width: ICON_SIZE,
  height: ICON_SIZE,
})

export const Wrapper = styled(Button)(({ theme, styled }: WrapperProps) => ({
  width: '100%',
  height: MENU_ITEM_HEIGHT,
  borderRadius: 0,
  '& *': {
    fill: styled.active ? theme.palette.primary.main : theme.palette.grey[100],
  },
}))
