import React, { FunctionComponent } from 'react'
import {
  ButtonProps as BaseButtonProps,
  CircularProgress,
} from '@material-ui/core'
import { Wrapper, LoaderWrapper, LOADER_SIZE } from './Button.style'

interface ButtonProps extends BaseButtonProps {
  isLoading?: boolean
}

const Component: FunctionComponent<ButtonProps> = ({
  isLoading = false,
  disabled,
  children,
  ...props
}) => {
  return (
    <Wrapper disabled={disabled || isLoading} {...props}>
      {isLoading && (
        <LoaderWrapper>
          <CircularProgress size={LOADER_SIZE} />
        </LoaderWrapper>
      )}
      {children}
    </Wrapper>
  )
}

export default Component
