import { styled, Typography as BaseTypography } from '@material-ui/core'

export const Typography = styled(BaseTypography)(({ theme }) => ({
  fontSize: 18,
  lineHeight: '24px',
  fontWeight: 300,
  color: theme.palette.text.primary,
  '& em': {
    textTransform: 'none',
    fontStyle: 'normal',
    color: theme.palette.primary.main,
  },
}))
