import { createSlice } from '@reduxjs/toolkit'
import { UiState } from './types'
import { REDUCER_NAME } from './config'

const initialState: UiState = {
  isLoading: false,
  errors: [],
  pageNumber: 1,
}

export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload
    },
  },
})

export default slice.reducer
