import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from 'config';

import enTranslation from './en.json';
import plTranslation from './pl.json';
import roTranslation from './ro.json';
import czTranslation from './cz.json';

const resources = {
  en: { global: enTranslation },
  pl: { global: plTranslation },
  ro: { global: roTranslation },
  cz: { global: czTranslation },
};

const supportedLanguages = ['en', config.LANGUAGE];

const getDefaultLang = () => {
  const currentKey = localStorage.getItem(config.APP_LANGUAGE);
  return currentKey && supportedLanguages.includes(currentKey)
    ? currentKey
    : config.LANGUAGE;
};

export default () => {
  i18n.use(initReactI18next).init({
    resources,
    defaultNS: 'global',
    lng: getDefaultLang(),
    fallbackLng: ['en'],
    interpolation: {
      escapeValue: false,
    },
  });
  return i18n;
};
