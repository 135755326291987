import { styled, TextField, WithTheme } from '@material-ui/core'
import { TextFieldProps } from './TextField'

interface ComponentProps extends TextFieldProps, WithTheme {
  styled: {
    disabled?: boolean
  }
}

export const Component = styled(TextField)(
  ({ theme, styled: { disabled } }: ComponentProps) => ({
    fontFamily: 'Roboto',
    width: '100%',
    borderBottom: `1px solid ${
      disabled ? theme.palette.grey[200] : theme.palette.text.secondary
    }`,
    '& *': {
      color: disabled ? theme.palette.grey[200] : theme.palette.text.secondary,
      pointerEvents: disabled ? 'none' : 'auto',
    },
  })
)

export const ErrorMessage = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  marginLeft: theme.spacing(),
}))
