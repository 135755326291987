import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Icon = (props: SvgIconProps) => (
  <SvgIcon
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    {...props}
  >
    <path
      d="M100.033 42.8141C99.2081 38.755 95.2486 36.1333 91.1895 36.9584C87.2115 37.7671 84.6141 41.5859 85.2884 45.5587L85.3338 45.8021C85.9319 48.7445 86.1322 51.7741 85.918 54.8369C84.6274 73.2933 68.6192 87.209 50.1628 85.9184C31.7063 84.6278 17.7906 68.6196 19.0812 50.1632C20.3718 31.7067 36.38 17.791 54.8364 19.0816C58.9685 19.3706 62.5524 16.2551 62.8413 12.1231C63.1303 7.99101 60.0148 4.40709 55.8828 4.11815C29.1622 2.24967 5.98623 22.3963 4.11774 49.1168C2.24926 75.8374 22.3958 99.0134 49.1164 100.882C75.837 102.75 99.013 82.6038 100.881 55.8832C101.19 51.4667 100.9 47.08 100.033 42.8141Z"
      fill="#C71B3D"
    />
  </SvgIcon>
)

export default Icon
