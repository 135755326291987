export default {
  landing: {
    black: '#161616',
    fitanuBlack: '#1A1A1A',
    red: '#D71921',
    gold: '#FAE14C',
    background: {
      paper: '#f2f2f2',
      default: '#171717',
    },
    primary: {
      main: '#D10A3D',
    },
    text: {
      primary: '#161522',
      secondary: '#212122',
      hint: '#A6A6A6',
    },
  },
  login: {
    grey: '#202020',
  },
  input: {
    grey: '#8d8d8d',
  },
  error: {
    main: '#CE262D',
  },
  success: {
    main: '#0D671C',
  },
}
