import {
  configureStore,
  CombinedState,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { combineReducers } from './reducer'
import { Module } from 'types'
import { UserState } from './user'
import { MemberState } from './member'
import { UiState } from './ui'
import { LandingState } from 'modules/landing/store'

const createStore = (modules: Module[], preloadedState: CombinedState<any>) => {
  const reducer = combineReducers(modules)
  return configureStore({
    reducer,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
    // preloadedState,
    devTools: true,
  })
}

export interface State extends UserState, MemberState, UiState, LandingState {}

export default createStore
