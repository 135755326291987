import 'fonts'
import React from 'react'
import ReactDOM from 'react-dom'
import App, { AppConfig } from 'components/App'
import modules from 'modules'
import * as serviceWorker from './serviceWorker'
import i18n from 'i18n'

i18n()

export const appConfig = new AppConfig(modules)

ReactDOM.render(
  <App routes={appConfig.routes} store={appConfig.store} />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
