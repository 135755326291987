import { styled, Button } from '@material-ui/core'

export const LOADER_SIZE = 24

export const Wrapper = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3.75),
  fontSize: 14,
  lineHeight: '24px',
  boxSizing: 'border-box',
  padding: theme.spacing(0, 6),
  position: 'relative',
  '& span': {
    letterSpacing: 0.5,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
}))

export const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  width: LOADER_SIZE,
  height: LOADER_SIZE,
  transform: 'translate(-50%, -50%)',
}))
