import {
  Grid,
  styled,
  WithTheme,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Button as BaseButton } from 'components'

export const IMAGE_WIDTH = 150
export const IMAGE_HEIGHT = 100
export const REMOVE_BUTTON_SIZE = 30

export const Wrapper = styled(Grid)(({ theme }) => ({
  width: 500,
  height: 300,
}))

interface UploadButtonProps extends WithTheme {
  styled: {
    active?: Boolean
  }
}

interface UploadImageProps extends WithTheme {
  styled: {
    url: string
  }
}

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[100],
  fontSize: 9,
  lineHeight: '10px',
  marginBottom: theme.spacing(0.5),
}))

export const UploadButton = styled(BaseButton)(
  ({ theme }: UploadButtonProps) => ({
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(2),
    marginTop: 0,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  })
)

export const Image = styled(Grid)(
  ({ theme, styled: { url } }: UploadImageProps) => ({
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    position: 'relative',
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.grey[400]}`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: `url('${url}')`,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  })
)

export const RemoveButton = styled(IconButton)(({ theme }) => ({
  width: REMOVE_BUTTON_SIZE,
  height: REMOVE_BUTTON_SIZE,
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.grey[400]}`,
  borderRadius: 100,
  backgroundColor: theme.palette.grey[500],
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(50%, -50%)',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '& *': {
    fill: theme.palette.common.white,
  },
}))
