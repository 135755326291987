import { Grid, WithTheme, styled } from '@material-ui/core'

export interface StyledProps {
  active?: boolean
  header?: boolean
}

interface WrapperProps extends WithTheme {
  styled: StyledProps
}

export const Wrapper = styled(Grid)(
  ({ theme, styled: { active, header } }: WrapperProps) => ({
    height: 56,
    boxSizing: 'border-box',
    fontSize: 14,
    lineHeight: '20px',
    backgroundColor: active ? theme.palette.grey[300] : theme.palette.grey[500],
    color: active ? theme.palette.common.white : theme.palette.grey[100],
    fontWeight: active ? 'bold' : 'normal',
    textTransform: header ? 'uppercase' : 'none',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.25),
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  })
)
