import React, { FunctionComponent, useState } from 'react'
import { InputLabel, Radio, FormControlLabel } from '@material-ui/core'
import { FormControl, RadioGroup as RadioGroupBase } from './RadioGroup.style'

interface RadioGroupProps {
  value?: any
  onChange?: (value: string) => any
  label?: string
  options: {
    title: string
    value: any
  }[]
  name: string
  disabled?: boolean
  formik?: boolean
}

const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  onChange,
  value,
  options,
  name,
  label,
  disabled = false,
  formik = false,
  ...props
}) => {
  const [radioValue, setRadioValue] = useState(options[0].value)
  const handleChange = (event: any) => {
    if (formik) return onChange!(event)
    if (onChange) {
      onChange(event.target.value)
    }
    setRadioValue(event.target.value)
  }
  return (
    <FormControl styled={{ disabled }}>
      <InputLabel shrink>{label}</InputLabel>
      <RadioGroupBase
        aria-label={name}
        name={name}
        value={value || radioValue}
        onChange={handleChange}
      >
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            control={<Radio color="default" />}
            label={option.title}
            key={`radio-${name}-${option.value}-${option.title}`}
            disabled={disabled}
          />
        ))}
      </RadioGroupBase>
    </FormControl>
  )
}

export default RadioGroup
