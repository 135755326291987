import { AppFlavor } from 'types';
export { default as theme } from './theme';
export { default as colors } from './colors';
export { default as strings } from './strings';

export interface Config {
  API_URL: string;
  API_KEY: string;
  FLAVOR: AppFlavor;
  APP_STATE_KEY: string;
  DEFAULT_DATE_FORMAT: string;
  APP_TOKEN_KEY: string;
  APP_LANGUAGE: string;
  REFRESH_TOKEN_KEY: string;
  TOKEN_EXPIRE_AT: string;
  PLACEHOLDER_URL: string;
  LANGUAGE: string;
}

export default {
  API_URL: process.env.REACT_APP_API_URL,
  API_KEY: process.env.REACT_APP_API_KEY,
  FLAVOR: process.env.REACT_APP_FLAVOR,
  LANGUAGE: process.env.REACT_APP_LANG,
  APP_STATE_KEY: 'appState',
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  APP_TOKEN_KEY: 'martes_token',
  APP_LANGUAGE: 'martes_language',
  REFRESH_TOKEN_KEY: 'martes_refresh_token',
  TOKEN_EXPIRE_AT: 'martes_expires_at',
  PLACEHOLDER_URL:
    'https://thumbs.dreamstime.com/b/no-image-available-icon-vector-illustration-flat-design-140476186.jpg',
} as Config;
