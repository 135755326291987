import { styled, WithTheme } from '@material-ui/core'
import styledComponent, { keyframes } from 'styled-components'
import { Loader as LoaderBase } from 'icons'
import { LoaderSize } from './Spinner'

interface WrapperProps extends WithTheme {
  white?: number
}

const spin = keyframes`
  0% {
    transform: rotate( 0deg );
  }
  100% {
    transform: rotate( 360deg );
  }
`

const getLoaderSize = (size: LoaderSize) => {
  if (size === 'small') return 30
  if (size === 'medium') return 48
  return 64
}

export const Wrapper = styled('div')(({ white }: WrapperProps) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 99999,
}))

export const Loader = styledComponent(LoaderBase)`
  animation-iteration-count: infinite;
  height: ${(p: { size: LoaderSize }) => getLoaderSize(p.size)}px;
  width: ${(p: { size: LoaderSize }) => getLoaderSize(p.size)}px;
  animation-name: ${spin};
  animation-duration: 1.6s;
` as any
