import { styled, WithTheme, Button as BaseButton } from "@material-ui/core";
import { isFitanu } from "config/theme";
import images from "images";
import { ShopButtonProps } from "./ShopButton";

interface Props extends ShopButtonProps, WithTheme {}

const BASE_BUTTON_WIDTH_LG_FITANU = 272;
const BASE_BUTTON_HEIGHT_LG_FITANU = 86;
const BASE_BUTTON_WIDTH_LG_MARTES = 220;
const BASE_BUTTON_HEIGHT_LG_MARTES = 56;
const BASE_BUTTON_WIDTH_MD = 200;
const BASE_BUTTON_WIDTH_SM = 160;

const getButtonHeight = (width: number) => width * 0.316;

export const Button = styled(BaseButton)(({ theme, shop }: Props) => ({
  ...(isFitanu()
    ? {
        minWidth: BASE_BUTTON_WIDTH_LG_FITANU,
        maxWidth: BASE_BUTTON_WIDTH_LG_FITANU,
        minHeight: BASE_BUTTON_HEIGHT_LG_FITANU,
        maxHeight: BASE_BUTTON_HEIGHT_LG_FITANU,
      }
    : {
        minWidth: BASE_BUTTON_WIDTH_LG_MARTES,
        maxWidth: BASE_BUTTON_WIDTH_LG_MARTES,
        minHeight: BASE_BUTTON_HEIGHT_LG_MARTES,
        maxHeight: BASE_BUTTON_HEIGHT_LG_MARTES,
      }),
  backgroundImage: `url('${
    shop === "google" ? images.googlePlay : images.appStore
  }')`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  borderRadius: 6,
  [theme.breakpoints.down("md")]: {
    minWidth: BASE_BUTTON_WIDTH_MD,
    maxWidth: BASE_BUTTON_WIDTH_MD,
    minHeight: getButtonHeight(BASE_BUTTON_WIDTH_MD),
    maxHeight: getButtonHeight(BASE_BUTTON_WIDTH_MD),
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: BASE_BUTTON_WIDTH_SM,
    maxWidth: BASE_BUTTON_WIDTH_SM,
    minHeight: getButtonHeight(BASE_BUTTON_WIDTH_SM),
    maxHeight: getButtonHeight(BASE_BUTTON_WIDTH_SM),
  },
}));
