import { AppFlavor, PerCountryConfig } from 'types';

import appStore from './appStore.png';
import card from './card.png';
import cardCz from './card_cz.svg';
import cardRo from './card_ro.svg';
import cardFitanu from './cardFitanu.png';
import fitanuBackgroundActivation from './fitanuBackgroundActivation.png';
import fitanuBlackLogo from './fitanuBlackLogo.png';
import fitanuLogo from './fitanuLogo.png';
import fitanuProcess from './fitanuProcess.png';
import footerBackground from './footerBG.png';
import footerBackgroundFitanu from './footerBGfitanu.png';
import googlePlay from './googlePlay.png';
import happyPhoto from './happyPhoto.png';
import joinClubMartes from './joinClub.svg';
import joinClubMartesCz from './joinClub_cz.svg';
import joinClubMartesRo from './joinClub_ro.svg';
import joinClubFitanu from './joinClubFitanu.png';
import martesLogo from './martesLogo.png';
import martesLogoInActivation from './martesLogoInActivation.png';
import personInActivation from './personInActivation.png';
import phone from './phone.png';
import phoneCz from './phone_cz.png';
import phoneRo from './phone_ro.png';
import phoneCropped from './phoneCropped.png';
import phoneCroppedCz from './phoneCropped_cz.png';
import phoneCroppedRo from './phoneCropped_ro.png';
import phoneCroppedFitanu from './phoneCroppedFitanu.png';
import phoneFitanu from './phoneFitanu.png';
import placeholder from './placeholder.jpg';
import promotion from './promotion.png';

type SharedImage = "logo" | "footerBackground";

type TranslatedImage = "card" | "phone" | "phoneCropped" | "joinClub";

type FlavorBasedImageConfig = Record<AppFlavor, string>;

interface Images
  extends Record<SharedImage, FlavorBasedImageConfig>,
    PerCountryConfig<Record<TranslatedImage, FlavorBasedImageConfig>> {
  appStore: string;
  googlePlay: string;
  placeholder: string;
  fitanuProcess: string;
  fitanuBlackLogo: string;
  martesLogoInActivation: string;
  fitanuBackgroundActivation: string;
  happyPhoto: string;
  promotion: string;
  personInActivation: string;
}

const commonImages = {
  appStore,
  googlePlay,
  placeholder,
  fitanuProcess,
  fitanuBlackLogo,
  martesLogoInActivation,
  happyPhoto,
  promotion,
  fitanuBackgroundActivation,
  personInActivation,
  logo: {
    MARTES: martesLogo,
    FITANU: fitanuLogo,
  },
  footerBackground: {
    MARTES: footerBackground,
    FITANU: footerBackgroundFitanu,
  },
};

const images: Images = {
  ...commonImages,
  cz: {
    card: {
      MARTES: cardCz,
      FITANU: cardFitanu,
    },
    phone: {
      MARTES: phoneCz,
      FITANU: phoneFitanu,
    },
    phoneCropped: {
      MARTES: phoneCroppedCz,
      FITANU: phoneCroppedFitanu,
    },
    joinClub: {
      MARTES: joinClubMartesCz,
      FITANU: joinClubFitanu,
    },
  },
  pl: {
    card: {
      MARTES: card,
      FITANU: cardFitanu,
    },
    phone: {
      MARTES: phone,
      FITANU: phoneFitanu,
    },
    phoneCropped: {
      MARTES: phoneCropped,
      FITANU: phoneCroppedFitanu,
    },
    joinClub: {
      MARTES: joinClubMartes,
      FITANU: joinClubFitanu,
    },
  },
  ro: {
    card: {
      MARTES: cardRo,
      FITANU: cardFitanu,
    },
    phone: {
      MARTES: phoneRo,
      FITANU: phoneFitanu,
    },
    phoneCropped: {
      MARTES: phoneCroppedRo,
      FITANU: phoneCroppedFitanu,
    },
    joinClub: {
      MARTES: joinClubMartesRo,
      FITANU: joinClubFitanu,
    },
  },
};

export default images;
