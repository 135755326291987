import {
  styled,
  Typography,
  Button,
  Grid,
  Collapse as BaseCollapse,
} from '@material-ui/core';
import { Globe } from 'icons';

export const Wrapper = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0.75, 1.5, 0.75, 1.5),
  background: theme.palette.grey[500],
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 10,
  position: 'relative',
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 400,
  fontSize: 16,
}));

export const Icon = styled(Globe)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

export const Collapse = styled(BaseCollapse)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  zIndex: 999999,
  right: 0,
  width: 'max-content',
  minWidth: '100%',
}));

export const ContentWrapper = styled(Grid)(({ theme }) => ({
  boxSizing: 'border-box',
  overflow: 'hidden',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.background.default,
  borderRadius: 10,
  justifyContent: 'space-between',
}));

export const LanguageWrapper = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: 0,
  '& *': {
    fontSize: 16,
    lineHeight: '24px',
  },
}));
