import {
  styled,
  FormControl as BaseFormControl,
  RadioGroup as BaseRadioGroup,
  WithTheme,
  FormControlProps as BaseFormControlProps,
} from '@material-ui/core'

interface FormControlProps extends WithTheme, BaseFormControlProps {
  styled?: {
    disabled?: boolean
  }
}

export const FormControl = styled(BaseFormControl)(
  ({ theme, styled }: FormControlProps) => ({
    width: '100%',
    height: 48,
    boxSizing: 'border-box',
    paddingTop: theme.spacing(2),
    '& div span': {
      color: `${
        styled?.disabled
          ? theme.palette.grey[200]
          : theme.palette.text.secondary
      } !important`,
    },
  })
)

export const RadioGroup = styled(BaseRadioGroup)(({ theme }) => ({
  flexDirection: 'row',
}))
