import React, { FunctionComponent } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { Option } from 'types'
import { TextField } from 'components'

interface AutocompleteProps {
  options: Option[]
  value?: any
  onChange: (value: any) => any
  customOnChange?: (value: Option) => any
  customOnInputChange?: (value: string) => any
  label?: string
  name?: string
  formError?: string
}

const Component: FunctionComponent<AutocompleteProps> = ({
  options,
  label = '',
  name,
  onChange,
  customOnChange,
  customOnInputChange,
  value: passedValue,
  formError,
  ...props
}) => {
  const [value, setValue] = React.useState<any>({
    title: '',
    value: 0,
  })
  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option.title}
      inputValue={passedValue}
      value={value}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          name={name}
          formError={formError}
        />
      )}
      onChange={(e, newValue) => {
        if (customOnChange) {
          customOnChange(newValue)
        }
        setValue(newValue)
      }}
      onInputChange={(e, newInputValue) => {
        const event = {
          persist: () => {},
          target: {
            type: 'change',
            name,
            value: newInputValue,
          },
        }
        if (customOnInputChange) {
          customOnInputChange(newInputValue)
        }
        onChange(event)
      }}
    />
  )
}

export default Component
