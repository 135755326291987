import { createSelector } from '@reduxjs/toolkit'
import { State } from 'store'

export const getUserState = (state: State) => state.user

export const isLoading = createSelector(getUserState, user => user.isLoading)

export const getCurrentUser = createSelector(
  getUserState,
  user => user.currentUser
)

export const getUsers = createSelector(
  getUserState,
  getCurrentUser,
  (userState, currentUser) =>
    userState.users.filter(user => user.id !== currentUser.id)
)
export const getUserDetails = createSelector(
  getUserState,
  user => user.userDetails
)

export const getSelectedUserIds = createSelector(
  getUserState,
  user => user.selectedUserIds
)
export const areAllSelected = createSelector(
  getSelectedUserIds,
  getUsers,
  (ids, users) => ids.length === users.length && !!ids.length
)

export const getUserErrors = createSelector(getUserState, user => user.errors)
