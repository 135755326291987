import React, { FunctionComponent } from 'react'
import { ButtonProps as BaseButtonProps } from '@material-ui/core'
import { getAppFlavor } from 'config/theme'
import { AppFlavor } from 'types'
import { Button } from './ShopButton.style'

type ShopType = 'google' | 'apple'

type ShopLink = Record<ShopType, string>

const ShopLinks: Record<AppFlavor, ShopLink> = {
  MARTES: {
    apple: 'https://apps.apple.com/pl/app/klub-martes-sport/id1538358754',
    google: 'https://play.google.com/store/apps/details?id=pl.martessport.club',
  },
  FITANU: {
    apple: 'https://apps.apple.com/pl/app/klub-fitanu/id1543604284?l=pl',
    google: 'https://play.google.com/store/apps/details?id=pl.fitanu.club ',
  },
}
export interface ShopButtonProps extends BaseButtonProps {
  shop: ShopType
}

const ShopButton: FunctionComponent<ShopButtonProps> = ({ shop, ...props }) => {
  const onClick = () => {
    const shopLink = ShopLinks[getAppFlavor()][shop]
    return !!shopLink && window.location.replace(shopLink)
  }
  return <Button {...props} shop={shop} onClick={onClick} />
}

export default ShopButton
