import { createSelector } from '@reduxjs/toolkit'
import { State } from 'store'

export const getMemberState = (state: State) => state.member

export const getMembers = createSelector(
  getMemberState,
  member => member.members
)
export const isLoading = createSelector(
  getMemberState,
  member => member.isLoading
)
export const areCitiesLoading = createSelector(
  getMemberState,
  member => member.areCitiesFetching
)
export const getSelectedMemberIds = createSelector(
  getMemberState,
  member => member.selectedMemberIds
)
export const areAllSelected = createSelector(
  getSelectedMemberIds,
  getMembers,
  (ids, members) => ids.length === members.length && !!ids.length
)
export const getCities = createSelector(getMemberState, member => member.cities)

export const getFilteredCities = createSelector(getCities, cities => {
  const availableCityNames: string[] = []
  return cities.filter(city => {
    if (availableCityNames.includes(city.name)) return false
    availableCityNames.push(city.name)
    return true
  })
})

export const getCityOptions = createSelector(getFilteredCities, cities =>
  cities.map(city => ({ title: city.name, value: city.id }))
)

export const getCityByName = (name: string) => {
  return createSelector(getFilteredCities, cities =>
    cities.find(city => city.name === name)
  )
}
