import { toast } from 'react-toastify'
import { strings } from 'config'

export const showToastError = (error?: string) => {
  toast.error(error || strings.SOMETHING_WENT_WRONG, {
    position: 'top-right',
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  })
}
