import React, { FunctionComponent, useState } from 'react'
import {
  TextFieldProps as BaseProps,
  InputProps,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Component, ErrorMessage } from './TextField.style'

export interface TextFieldProps
  extends Omit<BaseProps, 'onChange' | 'variant'> {
  onChange?: (value: any) => any
  formError?: string
  formik?: boolean
}

const TextField: FunctionComponent<TextFieldProps> = ({
  type,
  formik = false,
  onChange,
  disabled = false,
  label,
  formError,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const togglePassword = () => setShowPassword(!showPassword)
  const getType = () => {
    if (type === 'password' && showPassword) return 'text'
    return type
  }
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formik) return onChange!(e)
    if (onChange) {
      onChange(e.target.value)
    }
  }
  return (
    <Component
      InputProps={
        {
          disableUnderline: true,
          endAdornment:
            type === 'password' ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePassword}
                  onMouseDown={togglePassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : undefined,
        } as InputProps
      }
      onChange={onInputChange}
      styled={{ disabled }}
      label={
        <>
          {`${label}`}
          {formError && <ErrorMessage>{formError}</ErrorMessage>}
        </>
      }
      type={getType()}
      InputLabelProps={props.value ? { shrink: true } : undefined}
      {...props}
      inputProps={{
        ...props.inputProps,
        autoComplete: 'off',
        form: {
          autocomplete: 'off',
        },
      }}
    />
  )
}

export default TextField
