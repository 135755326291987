import {
  Grid,
  styled,
  IconButton as BaseIconButton,
  Typography,
} from '@material-ui/core'

const PAGINATION_HEIGHT = 56

export const Wrapper = styled(Grid)({
  width: '100%',
  height: PAGINATION_HEIGHT,
})

export const IconButton = styled(BaseIconButton)({
  width: PAGINATION_HEIGHT,
  height: PAGINATION_HEIGHT,
  padding: 0,
})

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  color: theme.palette.grey[100],
  fontFamily: 'Roboto',
  marginRight: theme.spacing(2.5),
}))
