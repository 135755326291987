import {
  styled,
  Select,
  MenuItem as MenuItemBase,
  FormControl as FormControlBase,
  WithTheme,
  SelectProps,
} from '@material-ui/core'

interface ComponentProps extends SelectProps, WithTheme {
  styled: {
    disabled?: boolean
  }
}

export const Component = styled(Select)(
  ({ theme, styled: { disabled } }: ComponentProps) => ({
    fontFamily: 'Roboto',
    height: 32,
    width: '100%',
    borderBottom: `1px solid ${
      disabled ? theme.palette.grey[200] : theme.palette.text.secondary
    }`,
    '& *': {
      color: disabled ? theme.palette.grey[200] : theme.palette.text.secondary,
      fill: disabled ? theme.palette.grey[200] : theme.palette.text.secondary,
    },
  })
)

export const MenuItem = styled(MenuItemBase)(({ theme }) => ({
  color: theme.palette.grey[100],
}))

export const FormControl = styled(FormControlBase)(({ theme }) => ({
  width: '100%',
}))
