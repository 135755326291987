import { Module, AnyReducer } from 'types'
import createStore from 'store'
import { CombinedState } from 'redux'
import config from 'config'
import { Route } from 'types'
import { mergeArrays, mergeObjects } from 'utils'

export interface Reducers {
  [key: string]: AnyReducer
}

export default class AppConfig {
  modules: Module[] = []
  routes: Route[] = []
  reducers: Reducers = {}
  store: any

  constructor(modules: Module[]) {
    this.modules = modules
    this.reducers = this.getConfigObject<AnyReducer>('reducer')
    this.routes = this.getConfigArray<Route>('routes')
    this.store = this.createStore()
  }

  private getConfigArray = <T>(key: keyof Module) =>
    this.modules.reduce(
      (configs, module) => mergeArrays(configs, module[key] as T[]),
      [] as T[]
    )

  private getConfigObject = <T>(key: keyof Module): { [key: string]: T } =>
    this.modules.reduce(
      (configs, module) => mergeObjects(configs, module.name, module[key]),
      {}
    )

  private createStore = () => {
    const persistedState = this.getPersistedState()
    const store = createStore(this.modules, persistedState)
    // store.subscribe(this.persistState)
    return store
  }

  private getPersistedState = (): CombinedState<any> => {
    try {
      const state = localStorage.getItem(config.APP_STATE_KEY)
      return state ? JSON.parse(state) : {}
    } catch (error) {
      console.error('Error when rehydrating the state.', error)
      return {}
    }
  }

  public persistState = () => {
    try {
      localStorage.setItem(
        config.APP_STATE_KEY,
        JSON.stringify(this.store.getState())
      )
    } catch (error) {
      console.error('Error when persisting the state.', error)
    }
  }
}
