import React, { FunctionComponent, useState } from 'react';
import {
  Wrapper,
  Icon,
  Title,
  Collapse,
  ContentWrapper,
  LanguageWrapper,
} from './LanguageSelector.style';
import { GridProps, ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import config from 'config';

const supportedLanguages = ['en', config.LANGUAGE];

const LanguageSelector: FunctionComponent<GridProps> = ({
  children,
  ...props
}) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const { i18n, t } = useTranslation();

  const onLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem(config.APP_LANGUAGE, lang);
  };

  const closeSelector = () => setIsSelectorOpen(false);
  const toggleSelector = () => setIsSelectorOpen(!isSelectorOpen);

  return (
    <ClickAwayListener onClickAway={closeSelector}>
      <Wrapper onClick={toggleSelector}>
        <Icon />
        <Title>{t(i18n.language.toUpperCase())}</Title>
        <Collapse in={isSelectorOpen} timeout="auto" unmountOnExit>
          <ContentWrapper container direction="column" alignItems="center">
            {[...supportedLanguages]
              .sort()
              .reverse()
              .map((lang) => (
                <LanguageWrapper
                  onClick={() => onLanguageChange(lang)}
                  key={`language-item-${lang}`}
                >
                  <Title>{t(lang.toUpperCase())}</Title>
                </LanguageWrapper>
              ))}
          </ContentWrapper>
        </Collapse>
      </Wrapper>
    </ClickAwayListener>
  );
};
export default LanguageSelector;
