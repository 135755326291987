import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PatchUserPassword, ResetUserPassword } from 'types'
import { post, patch } from 'utils/api'
import { UserState } from './types'
import { AxiosResponse } from 'axios'
import { request, success, failure } from '../reducer'
import { REDUCER_NAME } from './config'

const initialState: UserState = {
  isLoading: false,
  users: [],
  errors: [],
  currentUser: {
    createdAt: '',
    email: '',
    id: '',
    role: 'admin',
    updatedAt: '',
  },
  totalUserCount: 0,
  selectedUserIds: [],
  userDetails: undefined,
}

export const sendPasswordResetEmail = createAsyncThunk<any, ResetUserPassword>(
  `${REDUCER_NAME}/sendResetEmail`,
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await post('auth/reset-password', {
        data: {
          email,
          type: 'user',
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const patchUserPassword = createAsyncThunk<any, PatchUserPassword>(
  `${REDUCER_NAME}/patchUserPassword`,
  async ({ password, passwordConfirm, token }, { rejectWithValue }) => {
    try {
      const response = await patch('auth/reset-password', {
        data: {
          password,
          passwordConfirm,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sendPasswordResetEmail.pending, (state, action) => {
        request(state)
      })
      .addCase(sendPasswordResetEmail.fulfilled, (state, action) => {
        state.users = action.payload.data
        state.totalUserCount = action.payload.count
        success(state)
      })
      .addCase(sendPasswordResetEmail.rejected, (state, action: any) => {
        console.log(action)
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(patchUserPassword.pending, (state, action) => {
        request(state)
      })
      .addCase(patchUserPassword.fulfilled, (state, action) => {
        state.users = action.payload.data
        state.totalUserCount = action.payload.count
        success(state)
      })
      .addCase(patchUserPassword.rejected, (state, action: any) => {
        console.log(action)
        failure(state, { payload: action.payload as AxiosResponse })
      })
  },
})

export default slice.reducer
