import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Member, CreateMember, City, PaginatedResponse, WithId } from 'types'
import { get, post, put, del } from 'utils/api'
import { MemberState } from './types'
import config from 'config'
import { AxiosResponse } from 'axios'
import { request, success, failure } from '../reducer'
import { REDUCER_NAME } from './config'
import { getCityByName, getSelectedMemberIds } from './selectors'
import { State } from 'store'

const initialState: MemberState = {
  isLoading: false,
  arePurchasesAvailable: false,
  areCitiesFetching: false,
  selectedMemberIds: [],
  cities: [],
  errors: [],
  members: [],
  totalMemberCount: 0,
  memberDetails: undefined,
}

export const activateMemberAccount = createAsyncThunk<any, string>(
  `${REDUCER_NAME}/activateMemberAccount`,
  async (token, { rejectWithValue }) => {
    try {
      const response = await post('members/activation', {
        data: {
          token,
        },
        headers: {
          Authorization: `Basic ${config.API_KEY}`,
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const fetchAllMembers = createAsyncThunk<
  PaginatedResponse<Member>,
  object | undefined
>(`${REDUCER_NAME}/fetchAllMembers`, async (params, { rejectWithValue }) => {
  try {
    const response = await get('members', {
      params,
    })
    return response.data
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const deleteMembers = createAsyncThunk<any>(
  `${REDUCER_NAME}/deleteMembers`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await del('members', {
        data: {
          ids: getSelectedMemberIds(getState() as State),
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const fetchCities = createAsyncThunk<PaginatedResponse<City>, string>(
  `${REDUCER_NAME}/fetchCities`,
  async (term, { rejectWithValue }) => {
    try {
      const response = await get('cities', {
        headers: {
          Authorization: `Basic ${config.API_KEY}`,
        },
        params: {
          term,
          page: 1,
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const fetchMember = createAsyncThunk<Member, string | number>(
  `${REDUCER_NAME}/fetchMember`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await get(`members/${id}`)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const addMember = createAsyncThunk<any, CreateMember>(
  `${REDUCER_NAME}/addMember`,
  async (data, { rejectWithValue, getState }) => {
    try {
      const city = getCityByName(data.city)(getState() as State)
      const response = await post('members', {
        data: {
          ...data,
          state: city?.state.name || '',
          isEmployee: !!parseInt(data.isEmployee),
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const importEmployees = createAsyncThunk<any, File>(
  `${REDUCER_NAME}/importEmployees`,
  async (csvFile, { rejectWithValue }) => {
    try {
      const form = new FormData()
      form.append('csvFile', csvFile, 'workers.csv')
      const response = await post('members/employees', {
        data: form,
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const editMember = createAsyncThunk<any, CreateMember & WithId>(
  `${REDUCER_NAME}/editMember`,
  async ({ id, password, ...data }, { rejectWithValue, getState }) => {
    try {
      const city = getCityByName(data.city)(getState() as State)
      const response = await put(`members/${id}`, {
        data: {
          ...data,
          ...(password ? { password } : {}),
          state: city?.state.name || '',
          isEmployee: !!parseInt(data.isEmployee),
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const exportMemberPurchases = createAsyncThunk<any, string | number>(
  `${REDUCER_NAME}/exportMemberPurchases`,
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem(config.APP_TOKEN_KEY)
      window.open(
        `${config.API_URL}/members/${id}/purchases?token=${token}`,
        '_blank'
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const checkIfMemberPurchasesAvailable = createAsyncThunk<
  any,
  string | number
>(`${REDUCER_NAME}/exportMemberPurchases`, async (id, { rejectWithValue }) => {
  try {
    const response = await get(`members/${id}/purchases`)
    return response.data
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    resetMemberDetails: state => {
      state.memberDetails = undefined
    },
    toggleMemberSelect: (state, action) => {
      const isUserSelected = state.selectedMemberIds.some(
        id => id === action.payload
      )
      state.selectedMemberIds = isUserSelected
        ? state.selectedMemberIds.filter(id => id !== action.payload)
        : [...state.selectedMemberIds, action.payload]
    },
    toggleAllMembersSelect: state => {
      const areAllMembersSelected =
        state.members.length === state.selectedMemberIds.length
      state.selectedMemberIds = areAllMembersSelected
        ? []
        : state.members.map(member => member.id)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllMembers.pending, state => {
        request(state)
      })
      .addCase(fetchAllMembers.fulfilled, (state, action) => {
        state.members = action.payload.data
        state.totalMemberCount = action.payload.count
        success(state)
      })
      .addCase(fetchAllMembers.rejected, (state, action: any) => {
        console.log(action)
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(fetchMember.pending, state => {
        request(state)
      })
      .addCase(fetchMember.fulfilled, (state, action) => {
        state.memberDetails = action.payload
        success(state)
      })
      .addCase(fetchMember.rejected, (state, action: any) => {
        console.log(action)
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(addMember.pending, state => {
        request(state)
      })
      .addCase(addMember.fulfilled, state => {
        success(state)
      })
      .addCase(addMember.rejected, (state, action: any) => {
        console.log(action)
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(editMember.pending, state => {
        request(state)
      })
      .addCase(editMember.fulfilled, state => {
        success(state)
      })
      .addCase(editMember.rejected, (state, action: any) => {
        console.log(action)
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(fetchCities.pending, state => {
        state.areCitiesFetching = true
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload.data
        state.areCitiesFetching = false
      })
      .addCase(fetchCities.rejected, (state, action: any) => {
        console.log(action)
        state.areCitiesFetching = false
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(activateMemberAccount.pending, state => {
        request(state)
      })
      .addCase(activateMemberAccount.fulfilled, (state, action) => {
        success(state)
      })
      .addCase(activateMemberAccount.rejected, (state, action: any) => {
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(importEmployees.pending, state => {
        request(state)
      })
      .addCase(importEmployees.fulfilled, (state, action) => {
        success(state)
      })
      .addCase(importEmployees.rejected, (state, action: any) => {
        failure(state, { payload: action.payload as AxiosResponse })
      })
      .addCase(checkIfMemberPurchasesAvailable.pending, state => {
        state.arePurchasesAvailable = false
        request(state)
      })
      .addCase(checkIfMemberPurchasesAvailable.fulfilled, (state, action) => {
        state.arePurchasesAvailable = true
        success(state)
      })
      .addCase(
        checkIfMemberPurchasesAvailable.rejected,
        (state, action: any) => {
          state.isLoading = false
          state.arePurchasesAvailable = false
        }
      )
  },
})

export default slice.reducer
