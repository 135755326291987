import { Route } from 'types'
import { Loadable } from 'components'

export const paths = {
  root: '/',
}

export const routes: Route[] = [
  {
    path: paths.root,
    exact: true,
    component: Loadable({
      component: () => import('./components/Landing'),
    }),
  },
]

export default routes
