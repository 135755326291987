import React, { FunctionComponent } from 'react'
import { Typography } from './Typography.style'
import { TypographyProps } from '@material-ui/core'

const Component: FunctionComponent<TypographyProps> = ({
  children,
  ...props
}) => {
  return <Typography {...props}>{children}</Typography>
}
export default Component
