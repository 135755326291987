import { combineReducers as combine } from '@reduxjs/toolkit'
import { Module, LoadingState, ErrorState } from 'types'
import { AxiosResponse } from 'axios'
import config, { strings } from 'config'
import { default as user } from './user'
import { default as member } from './member'
import { default as ui } from './ui'
import { showToastError } from 'utils/errors'

export const unauthorizedStatuses = [401, 403]

export const combineReducers = (modules: Module[]) => {
  const config = modules.reduce((reducers, module) => {
    const { name, reducer } = module
    return {
      ...reducers,
      [name]: reducer,
    }
  }, {})
  return combine({
    ...config,
    user,
    member,
    ui,
  })
}

export const request = (state: LoadingState & ErrorState) => {
  state.isLoading = true
  state.errors = []
}

export const success = (state: LoadingState) => {
  state.isLoading = false
}

export const failure = (
  state: LoadingState & ErrorState,
  { payload }: { payload: AxiosResponse }
) => {
  if (unauthorizedStatuses.includes(payload.status)) {
    localStorage.removeItem(config.APP_TOKEN_KEY)
    window.location.replace('/')
  }
  showToastError()
  state.isLoading = false
  state.errors = [...state.errors, strings.SOMETHING_WENT_WRONG]
}
