import axios, { Method } from 'axios';
import config from 'config';

const getCommonHeaders = (settings?: any) => {
  const token = localStorage.getItem(config.APP_TOKEN_KEY);
  return {
    ...(settings?.disableAuthorization
      ? {}
      : { Authorization: `Bearer ${token}` }),
    'Content-Type': 'application/json',
    'x-language-code': config.LANGUAGE,
  };
};

const getRequest = async (
  endpoint: string,
  settings = { headers: {}, disableAuthorization: false },
  method: Method
) => {
  const { headers, disableAuthorization, ...options } = settings;
  const url = `${config.API_URL}/${endpoint}`;
  return axios({
    ...options,
    method,
    url,
    headers: {
      ...getCommonHeaders(settings),
      ...headers,
    },
  });
};

export const get = (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'GET');
export const post = (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'POST');
export const put = (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'PUT');
export const del = (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'DELETE');
export const patch = (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'PATCH');
