import { AppFlavor } from 'types';

import { createMuiTheme } from '@material-ui/core';

import colors from './colors';
import palettes from './palette';

export const BUTTON_BASE_HEIGHT = 44
export const INPUT_BASE_HEIGHT = 58
export const CHECKBOX_SIZE = 40

export const getAppFlavor = () =>
  (process.env.REACT_APP_FLAVOR || 'MARTES') as AppFlavor

export const isFitanu = () => getAppFlavor() === 'FITANU'

const palette = palettes[getAppFlavor()] || palettes.MARTES

const theme = createMuiTheme({ palette })

export const MAIN_FONT: { [key: string]: string } = {
  MARTES: 'Fira Sans',
  FITANU: 'Praktika',
}

export const getCurrentFont = (fallbackMartesFont?: string) => {
  const appFlavor = getAppFlavor()
  if (appFlavor !== 'MARTES' || !fallbackMartesFont) return MAIN_FONT[appFlavor]
  return fallbackMartesFont || MAIN_FONT[appFlavor]
}

export default createMuiTheme({
  palette,
  typography: {
    fontFamily: [getCurrentFont(), 'Roboto', 'Helvetica', 'Arial'].join(','),
  },
  breakpoints: {
    values: {
      xl: 1600,
      lg: 1366,
      md: 1025,
      sm: 600,
      xs: 0,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 20,
        height: BUTTON_BASE_HEIGHT,
        borderRadius: isFitanu() ? 0 : 5,
        textTransform: isFitanu() ? 'uppercase' : 'capitalize',
        padding: theme.spacing(0, 4),
        fontSize: 20,
        lineHeight: '27px',
        letterSpacing: 0.25,
      },
      text: {
        color: theme.palette.common.white,
        padding: '6px 16px',
        textTransform: 'capitalize',
      },
      contained: {
        boxShadow: '0 2px 4px 2px rgba(0,42,95,0.02)',
        '&$disabled': {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey[100],
        },
      },
      containedSecondary: {
        color: theme.palette.common.white,
        '&$disabled': {
          backgroundColor: theme.palette.grey[600],
          color: theme.palette.grey[100],
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: `${theme.palette.common.white} !important`,
        height: INPUT_BASE_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
      },
      input: {
        fontSize: 20,
        lineHeight: '24px',
        padding: theme.spacing(0, 3.5),
        flex: '1 1',
        color: colors.landing.text.secondary,
        '&::placeholder': {
          color: colors.landing.text.secondary,
          opacity: 1,
        },
      },
    },
    MuiInput: {
      root: {
        color: colors.input.grey,
      },
    },
    MuiCheckbox: {
      root: {
        width: CHECKBOX_SIZE,
        height: CHECKBOX_SIZE,
      },
    },
    MuiInputLabel: {
      shrink: {
        color: `${theme.palette.grey[100]} !important`,
      },
    },
    MuiSelect: {
      root: {
        height: INPUT_BASE_HEIGHT,
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: theme.palette.grey[500],
        '&:hover': {
          backgroundColor: theme.palette.grey[400],
        },
      },
    },
    MuiList: {
      root: {
        backgroundColor: theme.palette.grey[500],
      },
    },
    MuiFormControlLabel: {
      root: {
        color: theme.palette.grey[100],
      },
    },
    MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    },
  },
})
