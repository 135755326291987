import { UserRole } from 'types'
import { TFunction } from 'i18next'

export const canUserSave = (role: UserRole) => {
  return role !== 'read'
}

export const canUserDelete = (role: UserRole) => {
  return role === 'admin'
}

export const getUserPermissions = (role: UserRole) => {
  if (role === 'write') return ['read', 'write']
  if (role === 'admin') return ['read', 'write', 'admin']
  return ['read']
}
export const roleOptions: {
  titleKey: string
  value: UserRole
}[] = [
  {
    value: 'admin',
    titleKey: 'ADMINISTRATOR',
  },
  {
    value: 'read',
    titleKey: 'READ_ONLY',
  },
  {
    value: 'write',
    titleKey: 'READ_AND_WRITE',
  },
]

export const getRoleOptions = (currentUserRole: UserRole, t: TFunction) => {
  const currentUserPermissions = getUserPermissions(currentUserRole)
  return roleOptions
    .filter(role => currentUserPermissions.includes(role.value))
    .map(item => ({ value: item.value, title: t(item.titleKey) }))
}

export const getRoleTitle = (userRole: UserRole, t: TFunction) => {
  const roleKey = roleOptions.find(role => role.value === userRole)?.titleKey
  return roleKey ? t(roleKey) : t(roleOptions[0].titleKey)
}
