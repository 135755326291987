export const martesPalette = {
  background: {
    paper: '#f2f2f2',
    default: '#191919',
    confirmation: '#d10a11'
  },
  primary: {
    main: '#C71B3D',
  },
  secondary: {
    main: '#191919',
  },
  success: {
    main: '#0BC575',
  },
  error: {
    main: '#F31F4A',
  },
  text: {
    primary: '#161522',
    secondary: '#F4F4F4',
    hint: '#A6A6A6',
    confirmation: '#fff'
  },
  grey: {
    100: '#757575', // list font
    200: '#494949', // left menu icons
    300: '#393939',
    400: '#323232', // list row hover
    500: '#282828', // list row background
    600: '#202020', // view background
  },
}

export const fitanuPalette = {
  primary: {
    main: '#D51F5A',
  },
  background: {
    ...martesPalette.background,
    confirmation: '#000'
  }, 

}

export default {
  MARTES: martesPalette,
  FITANU: fitanuPalette,
}
