import { Route } from 'types'
import { Loadable } from 'components'
import { paths } from './strings'

export const routes: Route[] = [
  {
    path: paths.root,
    exact: true,
    component: Loadable({
      component: () => import('./components/PasswordReset'),
    }),
  },
]

export default routes
