import {
  Grid,
  Typography,
  Button as BaseButton,
  styled,
} from '@material-ui/core'

const BUTTON_HEIGHT = 44
const BUTTON_WIDTH = 220

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  lineHeight: '28px',
  color: theme.palette.grey[100],
}))

export const Button = styled(BaseButton)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '24px',
  width: BUTTON_WIDTH,
  height: BUTTON_HEIGHT,
  '& span': {
    letterSpacing: 0.5,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  '&:not(:last-child)': {
    marginRight: theme.spacing(2.75),
  },
}))

export const Header = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export const ButtonWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
}))
