import React from 'react'
import MomentUtils from '@date-io/moment'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { BrowserRouter, Switch } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Redirect, Route } from 'react-router-dom'
import { Route as RouteProps } from 'types'
import { Provider } from 'react-redux'
import { AnyAction, Store } from 'redux'
import { ToastContainer } from 'react-toastify'
import { theme } from 'config'
import 'react-toastify/dist/ReactToastify.css'
interface AppProps {
  routes: RouteProps[]
  store: Store<any, AnyAction>
}

const App = ({ routes, store }: AppProps) => (
  <Provider store={store}>
    <ToastContainer />
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            {routes.map(route => (
              <Route key={route.path?.toString()} {...route} />
            ))}
            <Route component={() => <Redirect to="/" />} path="/" />
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>
)

export default App
