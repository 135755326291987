import { useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { State } from 'store'

import { useTheme } from '@material-ui/core'

export const useWidth = () => {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output: any, key: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

export const usePreviousBreakpoint = () => {
  const theme = useTheme()
  const width = useWidth()
  const keys = [...theme.breakpoints.keys]
  const key = keys[keys.findIndex(item => item === width) - 1] || 'xs'
  return key
}

export const useLanding = () => {
  return useSelector((state: State) => state.landing)
}

export const useQuery = () => new URLSearchParams(useLocation().search)

export const useQueryParam = (param: string) => useQuery().get(param)

export const useDebouncedValue = (value: string, time = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value)
    }, time)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, time])

  return debouncedValue
}
