import React, { FunctionComponent } from 'react'
import { numericBoolean } from 'utils'
// import { Loader } from 'icons'
import { Wrapper, Loader } from './Spinner.style'

export type LoaderSize = 'small' | 'medium' | 'big'

interface SpinnerProps {
  size?: LoaderSize
  white?: boolean
}

const Spinner: FunctionComponent<SpinnerProps> = ({
  size = 'small',
  white = false,
  ...props
}) => (
  <Wrapper white={numericBoolean(white)} {...props}>
    <Loader size={size} />
  </Wrapper>
)

export default Spinner
